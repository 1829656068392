import styled, { keyframes } from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 16px;
`;
export const StyledPause = styled.div`
    display: flex;
    background: transparent;
    width: 100%;
    height: 100%;
    position: relative;
    bottom: 0;
    left: 0;
    z-index: 1000;
    justify-content: center;
    align-items: center;
`;
const StyledFrame = keyframes`
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(720deg);
        transform: rotate(720deg);
    }
`;
export const StyledBlur = styled.div<{ $inverted: boolean }>`
    -webkit-animation: ${StyledFrame} 1s infinite ease;
    animation: ${StyledFrame} 1s infinite ease;
    background: transparent;
    border: 3px solid transparent;
    border-radius: 100%;
    border-top-color: ${(p) =>
        p.$inverted ? p.theme.colors.white : p.theme.colors.primary};
    width: 27px;
    height: 27px;
    opacity: 0.8;
    padding: 0;
    position: absolute;
    z-index: 999;
    filter: blur(3px);
`;
export const StyledSpinner = styled.div<{ $inverted: boolean }>`
    -webkit-animation: ${StyledFrame} 1s infinite ease;
    animation: ${StyledFrame} 1s infinite ease;
    background: transparent;
    border: 3px solid transparent;
    border-radius: 100%;
    border-top-color: ${(p) =>
        p.$inverted ? p.theme.colors.white : p.theme.colors.primary};
    width: 27px;
    height: 27px;
    opacity: 0.8;
    padding: 0;
    position: absolute;
    z-index: 999;
`;
